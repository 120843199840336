html,
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background-color: var(--bg-color);
  color: var(--text-color);
}

#root {
  width: 100%;
  height: 100%;
}

:root {
  --accent-color: #fe003a;
  --bg-color: black;
  --text-color: white;
}
